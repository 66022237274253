module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-134438502-1","G-SXKDBWT5VM"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Anthony's Website","short_name":"Anthony's Site","start_url":"/","background_color":"#FFFFFF","theme_color":"#60A5FA","display":"minimal-ui","icon":"src/assets/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2e219ee2ff29a97f38b58cb004742426"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
